import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import { Article, ArticleWrapper, CommunityCard, NewSpace, SectionOneContent, SectionOneWrapper, SectionThreeContent, SectionThreeWrapper, SectionTwoContent, SectionTwoWrapper, Wrapper } from './style'
import { FiChevronRight } from 'react-icons/fi'
import { BsCreditCard } from 'react-icons/bs'

const HomeScreen: React.FC = ()  => {
    return (
        <>
            <Header />
            <Wrapper>
                <SectionOneWrapper>
                    <SectionOneContent>
                        <div className="row-1">
                            <h3>About Us</h3>

                            <p>We are at the forefront of innovation, dedicated to building the next generation of web and mobile experiences. With a relentless commitment to pushing the boundaries of technology, we are creating cutting-edge Web 3 mobile apps and web applications that redefine the digital landscape.</p>
                        </div>

                        <div className="row-2">
                            <div className="text-content">
                                <div className="icon-box">
                                    <BsCreditCard />
                                </div>
                                <h3>Our Mission</h3>

                                <p>We are on the move for global empowerment with cutting-edge Web 3 mobile apps and web applications. We firmly believe that the future of the digital world lies in decentralization, blockchain technology, and the limitless potential of the internet. Our goal is to harness these technologies to create unparalleled digital experiences that transform the way people work, play, and connect.</p>
                            </div>
                            <div className="img-content">
                                <img src="/assets/img/mission.jpg" alt="" />
                            </div>
                        </div>
                    </SectionOneContent>
                </SectionOneWrapper>

                <SectionTwoWrapper>
                    <SectionTwoContent>
                        <h2>Check out our amazing products</h2>

                        <div className="products-wrapper">
                            <div className="products">
                                <h3>Facetally</h3>
                                <p>A modern way to connect with new people of similar intrests and build relationships that last</p>
                                
                                <div className="icon-box">
                                    <FiChevronRight />
                                </div>
                            </div>
                            <div className="products">
                                <h3>Profyyl</h3>
                                <p>Levaraging the blockchain to create an amazing platform for people to connect with each other</p>
                                
                                <div className="icon-box">
                                    <FiChevronRight />
                                </div>
                            </div>
                            <div className="products">
                                <h3>Facetally</h3>
                                <p>A modern way to connect with new people of similar intrests and build relationships that last</p>
                                
                                <div className="icon-box">
                                    <FiChevronRight />
                                </div>
                            </div>
                            <div className="products">
                                <h3>Profyyl</h3>
                                <p>Levaraging the blockchain to create an amazing platform for people to connect with each other</p>
                                
                                <div className="icon-box">
                                    <FiChevronRight />
                                </div>
                            </div>
                        </div>
                    </SectionTwoContent>
                </SectionTwoWrapper>

                <SectionThreeWrapper>
                    <SectionThreeContent>
                        <div className="content">
                            <h1>Get early access to our products</h1>
                            <p>We are at the forefront of innovation, dedicated to building the next generation of web and mobile experiences.</p>
                            <a href="#">Get Started</a>
                        </div>
                    </SectionThreeContent>
                </SectionThreeWrapper>
            </Wrapper>
            <Footer />
        </>
    )
}

export default HomeScreen