import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { HeadBar, HeaderWrapper, Nav, NavContent, SlideImgWrapper, SlideTextWrapper, SlideWrapper, ToggleMenu } from './styles'
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu, logoutUser} from '../../../actions'

const Header: React.FC = ()  => {
    const dispatch = useDispatch();


    const toggleState = useSelector((state: {navigation: any}) => state.navigation);

    const sidebarRef = useRef(null)
    const contentRef = useRef(null)

    const closeMenu = (e: { target: any }) => {
        if (e.target == sidebarRef.current) {
            dispatch(toggleMenu());
        }
    }

    return (
        <HeaderWrapper>
            <HeadBar>
                <div className="banner">
                    <img src="/assets/img/logo.png" alt="logo" />
                    <h2>Face Technologies</h2>
                </div>
                <Nav ref={sidebarRef} onClick={e => closeMenu(e)} navigationState={toggleState}>
                    <NavContent navigationState={toggleState}>
                        <div className="nav-head">
                            <img src="/assets/img/logo.png" alt="logo" />
                            <h2>Face Technologies</h2>
                        </div>
                        <ul>
                            <li><a href="#">Service</a></li>
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Pricing</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                        <ul>
                            <li><Link to="/">login</Link></li>
                            <li><Link to="/">Signup</Link></li>
                        </ul>
                    </NavContent>
                </Nav>
                <ToggleMenu onClick={() => dispatch(toggleMenu())}>
                    <span></span>
                </ToggleMenu>
            </HeadBar>
            <SlideWrapper>
                <SlideTextWrapper>
                    {/* <span className="tag">Connecting teams</span> */}
                    <h1>Welcome to Face Technologies Ltd. - Limitless possibilies using web3</h1>
                    <h6>We are on the move for global empowerment with cutting-edge Web 3 mobile apps and web applications.</h6>
                </SlideTextWrapper>
                <SlideImgWrapper>
                    <img src="/assets/img/banner.jpg" alt="banner" />
                </SlideImgWrapper>
            </SlideWrapper>
        </HeaderWrapper>
    )
}

export default Header