import styled from 'styled-components'

export const Wrapper = styled.div`
    background-color: #fff;
    position: relative;
    z-index: 99;
`;
export const SectionOneWrapper = styled.div`
    background-color: #fff;
`;
export const SectionOneContent = styled.div`
    background-color: #fff;
    padding: 100px 8%;

    .row-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 80px;

        h3 {
            margin-bottom: 22px;
            font-weight: 800;
            text-align: center;
            font-size: 25px;
            color: ${props => props.theme.primaryColor};
        }

        p {
            text-align: center;
            font-size: 16px;
            line-height: 23px;
            font-weight: 400;
            color: #222;
            max-width: 720px;
        }
    }

    .row-2 {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .text-content {
            .icon-box {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                width: 40px;
                border-radius: 4px;
                margin-bottom: 10px;
                background-color: ${props => props.theme.primaryColor};
                color: #fff;
                font-size: 14px;
            }
            h3 {
                margin-bottom: 10px;
                font-weight: 600;
                font-size: 18px;
            }

            p {
                font-size: 15px;
                line-height: 20px;
                color: #222;
                font-weight: 300;
            }
        }

        .img-content {
            width: 100%;
            max-width: 480px;
        }

        @media screen and (min-width: 880px) {
            flex-direction: row;

            .text-content {
                max-width: 480px;

                h3 {
                    font-size: 22px;
                }

                p {
                    font-size: 17px;
                    line-height: 25px;
                }
            }
        }
    }
`;

export const SectionTwoWrapper = styled.div`
    background-color: #f7f7f7;
    padding: 100px 8%;
`;
export const SectionTwoContent = styled.div`
    h2 {
        color: #222;
        font-size: 25px;
        font-weight: 900;
        margin-bottom: 40px;
        text-align: center;
    }

    .products-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .products {
            background-color: ${props => props.theme.primaryColor};
            padding: 20px;
            border-radius: 8px;
            width: 100%;

            h3 {
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            p {
                font-weight: 300;
                font-size: 14px;
                color: #fff;
            }

            .icon-box {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-top: 20px;
                color: ${props => props.theme.primaryColor};
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: #fff;
            }

            @media screen and (min-width: 640px) {
                width: calc(50% - 10px);
            }


            @media screen and (min-width: 1080px) {
                width: calc(25% - 15px);
            }
        }
    }
`;
export const CommunityCard = styled.div`
    background-color: #fff;
    border-radius: 10px;
    border: 7px solid #83afd6;
    border-bottom-width: 15px;
    margin-bottom: 25px;

    .head {
        background-color: #FFDC5D;
        padding: 15px 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin-top: -10px;
        h2 {
            color: #000;
            font-size: 16px;
            font-weight: 400;
        }
    }
    .row {
        display: flex;
        flex-direction: column;
        padding: 20px;
        background: #fff;
        margin-bottom: -8px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        @media screen and (min-width: 880px) {
            flex-direction: row;
        }

        .sec {
            display: flex;
            flex: 1;
            margin-bottom: 20px;
            @media screen and (min-width: 880px) {
                margin-bottom: 0px;
            }
            img {
                width: 70px;
                height: 70px;

                margin-right: 20px;
            }
            .col {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 8px 0px;
                flex: 1;
                h4 {
                    color: #000;
                    font-size: 14px;
                    font-weight: 600;
                }
                h6 {
                    color: #000;
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }
        a {
            white-space: nowrap;
            padding: 12px 20px;
            border-radius: 8px;
            background-color: #FFDC5D;
            font-size: 13px;
            font-weight: 800;
            color: #494747;
            align-self: flex-end;
        }
    }
`;

export const NewSpace = styled.div`
    background-color: #fff;
    outline: 5px solid #83afd6;
    padding: 30px 20px;
    border-radius: 10px;
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
    flex-direction:column ;
    align-items: center;

    h4 {
        color: #000;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 20px;
    }
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #450555;
        padding: 12px 40px;
        font-size: 14px;
        font-weight: 400;
        border-radius: 8px;
        color: #000;
        white-space: nowrap;
    }

    @media screen and (min-width: 880px) {
        flex-direction: row;
        h4 {
            font-weight: 700;
            margin-bottom: 0px;
            padding-left: 30px;
        }
    }
`;



export const SectionThreeWrapper = styled.div`
    background-color: #fff;
    position: relative;
    overflow: hidden;
    ::before {
        content: "";
        display: block;
        position: absolute;
        background-color: #fff;
        height: 100%;
        width: 100%;
        bottom: -350px;
        left: 0px;

        @media screen and (min-width: 880px) {
            bottom: -200px;
        }
    }
`;
export const SectionThreeContent = styled.div`
    position: relative;
    padding: 100px 8%;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 20px;
        background-color: ${(prop) => prop.theme.primaryColor};
        border-radius: 10px;
        max-width: 1080px;
        margin: 0px auto;

        h1 {
            color: #fff;
            font-size: 27px;
            font-weight: 900;
            margin-bottom: 20px;
            text-align: center;
        }

        p {
            color: #fff;
            font-size: 17px;
            font-weight: 400;
            margin-bottom: 40px;
            text-align: center;
            margin-bottom: 20px;
            max-width: 640px;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            color: ${(prop) => prop.theme.primaryColor};
            width: 150px;
            height: 40px;
            font-size: 14px;
            font-weight: 600;
            border-radius: 20px;
            margin: 0px auto;
        }
    }
`;
export const ArticleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;

    @media screen and (min-width: 880px) {
        flex-direction: row;
    }
`;
export const Article = styled.div`
    width: 100%;
    max-width: 320px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0px 0px 50px 0px;

    @media screen and (min-width: 880px) {
        margin: 0px;
    }

    :nth-child(2) {
        @media screen and (min-width: 880px) {
            margin: 0px 50px;
        }
    }

    .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ccc;
        height: 200px;
        position: relative;
        img {
            min-width: 100%;
            min-height: 100%;
            position: absolute;
        }
    }
    .post-content {
        padding: 20px 0px;
        span {
            color: #000;
            font-weight: 300;
            font-size: 14px;
        }
        h3 {
            margin-top: 10px;
            color: #000;
            font-weight: 800;
            font-size: 18px;
            line-height: 25px;
        }
        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;
            a {
                color: #7AA9F0;
                font-size: 14px;
                font-weight: 600;
                
                :last-child {
                    padding-right: 20px;
                    img {
                        width: 15px;
                    }
                }
            }
        }
    }
`;